export default {
  title: 'E-Time',
  home: 'Home',
  tabs: {
    time: 'Time',
    'special-allowance': 'Special-allowance'
  },
  'table-branch': {
    id: 'id',
    branchCode: 'Branch code',
    describe: 'Describe'
  },
  'table-special-allowance': {
    id: 'id',
    code: 'Allowance code',
    name: 'Allowance name',
    costCenterName: 'Cost center name',
    'add-button': 'Add special allowance',
    min: 'Min value',
    max: 'Max value',
    action: 'Action'
  },
  'main-setting': {
    'edit-title': 'Edit main setting',
    'sub-title': { ot: 'OT setting', time: 'Time setting' },
    form: {
      enableCardMapping: 'Enable card mapping',
      enableAppEditTime: 'Enable approver can edit time',
      division: 'Division',
      department: 'Department',
      workflowCode: 'Workflow code'
    }
  },
  form: {
    'sp-allowance': {
      code: {
        label: 'Allowance code'
      },
      name: {
        label: 'Allowance name'
      },
      minCost: {
        label: 'Min value'
      },
      maxCost: {
        label: 'Max value'
      },
      costCenterCode: {
        label: 'Cost Center'
      },
      workflowCode: {
        label: 'Workflow'
      }
    }
  }
}
