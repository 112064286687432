export default {
  form: {
    'import-leave-file': 'Upload File (.csv , .xlsx)',
    title: {
      'import-leave': 'Leave Day Employee Document Management'
    }
  },
  'import-table': {
    row: 'ลำดับ',
    empCode: 'รหัสพนักงาน',
    detail: 'รายละเอียด',
    status: 'สถานะ'
  }
}
