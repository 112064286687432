export default {
  title: 'บริหารเวลาออนไลน์',
  home: 'หน้าหลัก',
  company: 'บริษัท',
  'delete-row-modal': {
    title: 'E-Certificate',
    content: 'คุณต้องการลบรายการที่เลือกหรือไม่',
    button: {
      accept: 'ใช่',
      cancel: 'ไม่ใช่'
    }
  },
  form: {
    startPeriod: {
      label: 'วันเริ่มต้น',
      placeholder: 'วันเริ่มต้น'
    },
    endPeriod: {
      label: 'วันสิ้นสุด',
      placeholder: 'วันสิ้นสุด'
    }
  }
}
