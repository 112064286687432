export default {
  main: {
    EPayslipPage: require('./pages/main/EPayslipPage').default,
    ELeavePage: require('./pages/main/ELeavePage').default,
    ELeaveReportPage: require('./pages/main/ELeaveReportPage').default,
    ELeaveApprovalPage: require('./pages/main/ELeaveApprovalPage').default,
    ETimeApprovalPage: require('./pages/main/ETimeApprovalPage').default,
    ETimePage: require('./pages/main/ETimePage').default,
    DashboardPage: require('./pages/main/DashboardPage').default,
    DelegatePage: require('./pages/main/DelegatePage').default,
    ApproveEmailPage: require('./pages/main/ApproveEmailPage').default,
    ETimeDelegatePage: require('./pages/main/ETimeDelegatePage').default
  },
  management: {
    loginConfigPage: require('./pages/management/loginConfigPage').default,
    SuperEPaySlipConfigPage:
      require('./pages/management/SuperEPaySlipConfigPage').default,
    SuperECertificatePage: require('./pages/management/SuperECertificatePage')
      .default,
    SuperETimeConfigPage: require('./pages/management/SuperETimeConfigPage')
      .default,
    ELeaveConfigPage: require('./pages/management/ELeaveConfigPage').default,
    EPayslipPage: require('./pages/management/EPayslipPage').default,
    EPaySlipConfigPage: require('./pages/management/EPaySlipConfigPage')
      .default,
    ECertificatePage: require('./pages/management/ECertificatePage').default,
    ETimeConfigPage: require('./pages/management/ETimeConfigPage').default,
    HrELeavePage: require('./pages/management/HrELeavePage').default,
    PortalPage: require('./pages/management/PortalPage').default,
    EditNewsPage: require('./pages/management/EditNewsPage').default,
    WorkflowPage: require('./pages/management/WorkflowPage').default,
    AuditLogPage: require('./pages/management/AuditLogPage').default,
    HrETimePage: require('./pages/management/HrETimePage').default,
    ImportPage: require('./pages/management/ImportPage').default,
    ImportLeavePage: require('./pages/management/ImportLeavePage').default,
    PayPeriodPage: require('./pages/management/PayPeriodPage').default,
    PermPage: require('./pages/management/PermPage').default
  },
  common: {
    ECertificatePage: require('./pages/common/ECertificatePage').default
  }
}
