export default {
  POST_LOGIN: {
    url: '/authentications/auth/login',
    isPublic: true,
    method: 'POST'
  },
  POST_RESIGN_OTP: {
    url: '/authentications/auth/resignOtp',
    isPublic: true,
    method: 'POST'
  },
  POST_VERIFY_OTP: {
    url: '/authentications/auth/verifyOtp',
    isPublic: true,
    method: 'POST'
  },
  POST_INIT_PASSWORD: {
    url: '/authentications/auth/initPassword',
    isPublic: true,
    method: 'POST'
  },
  GET_ALL_USER: {
    url: '/authentications/user/getAllUser',
    method: 'POST'
  },
  GET_APPROVER_USER: {
    url: '/authentications/user/getApproverList',
    method: 'POST'
  },
  GET_EMPLOYEE_STATUS: {
    url: '/authentications/user/pisEmployeeStatus'
  },
  GET_JWT_FROM_SSO_TOKEN: {
    url: '/authentications/auth/sso-token',
    isPublic: false,
    method: 'GET'
  },
  GET_EMP_BY_SUPERVISOR: {
    url: '/authentications/user/getEmpBySupervisor',
    isPublic: false,
    method: 'GET'
  }
}
