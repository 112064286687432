export default {
  GET_ALL_COMPANY: {
    url: '/e-general/company',
    isPublic: false
  },
  GET_BY_GROUP_CODE: {
    url: '/e-general/company/getByGroupCode/',
    isPublic: true,
    localUrl: 'http://localhost:8003/company/getByGroupCode/GO_COMPANY',
    useLocal: false
  },
  GET_ADMIN_CONFIG: {
    url: '/e-general/company/setting/',
    isPublic: true
  },
  GET_CONFIG: {
    url: '/e-general/company/setting/',
    isPublic: true
  },
  GET_ROLE: {
    url: '/e-general/role/roleType',
    isPublic: false
  },
  GET_MAIN_SETTING: {
    url: '/e-general/company/getCompanyMainSetting/',
    isPublic: false
  },
  GET_CHANGE_PASSWORD_SETTING: {
    url: '/e-general/company/getChangePasswordSetting/',
    isPublic: false
  },
  GET_FORGOT_PASSWORD_SETTING: {
    url: '/e-general/company/getForgotPasswordSetting/',
    isPublic: false
  },
  GET_PORTAL_SETTING: {
    url: '/e-general/company/getPortalSetting/',
    isPublic: false
  },
  GET_EPAY_SLIP_SETTING: {
    url: '/e-general/company/getEPayslipSetting/',
    isPublic: false
  },
  PATCH_CHANGE_PASSWORD_SETTING: {
    url: '/e-general/company/updateChangePasswordSetting/',
    method: 'PUT',
    isPublic: false
  },
  PATCH_FORGOT_PASSWORD_SETTING: {
    url: '/e-general/company/updateForgotPasswordSetting/',
    method: 'PUT',
    isPublic: false
  },
  PATCH_PORTAL_SETTING: {
    url: '/e-general/company/updatePortalSetting/',
    method: 'PUT',
    isPublic: false
  },
  PATCH_MAIN_SETTING: {
    url: '/e-general/company/updateCompanyMainSetting/',
    method: 'PUT',
    isPublic: false
  },
  PATCH_EPAY_SLIP_SETTING: {
    url: '/e-general/company/updateEPayslipSetting/',
    method: 'PUT',
    isPublic: false
  },
  GET_ALL_NEWS_ADMIN: {
    url: '/e-general/news',
    method: 'GET'
  },
  GET_NEWS_ADMIN: {
    url: '/e-general/news',
    method: 'GET'
  },
  GET_ALL_NEWS: {
    url: '/e-general/dashboard/news',
    method: 'GET'
  },
  GET_NEWS: {
    url: '/e-general/dashboard/news/',
    method: 'GET'
  },
  CREATE_NEWS: {
    url: '/e-general/news',
    method: 'POST'
  },
  UPDATE_NEWS: {
    url: '/e-general/news',
    method: 'PUT'
  },
  DELETE_NEWS: {
    url: '/e-general/news',
    method: 'DELETE'
  },
  DELETE_COMPANY: {
    url: '/e-general/company',
    method: 'DELETE',
    isPublic: false
  },
  GET_ALL_CERTIFICATE: {
    url: '/e-general/eCertificate',
    isPublic: false
  },
  DELETE_CERTIFICATE: {
    url: '/e-general/eCertificate',
    method: 'DELETE'
  },
  GET_CERTIFICATE: {
    url: '/e-general/eCertificate/',
    method: 'GET'
  },
  CREATE_CERTIFICATE: {
    url: '/e-general/eCertificate',
    method: 'POST'
  },
  UPDATE_CERTIFICATE: {
    url: '/e-general/eCertificate/',
    method: 'PUT'
  },
  GET_CONFIG_CERTIFICATE: {
    url: '/e-general/eCertificate/conf',
    method: 'GET'
  },
  GET_COUNTRY_CERTIFICATE: {
    url: '/e-general/eCertificate/country',
    method: 'GET'
  },
  GET_OCTREE: {
    url: '/e-general/company/ocTree',
    method: 'GET'
  },
  GET_COMPANY_JOB_MASTER: {
    url: '/e-general/company/jobMaster',
    method: 'GET'
  },
  GET_STATUS_EMPLOYEE: {
    url: '/e-general/pisEmployee/status',
    method: 'GET'
  },
  GET_NOTIFICATION: {
    url: '/e-general/notification'
  },
  UPDATE_NOTIFICATION: {
    url: '/e-general/notification/',
    method: 'PUT'
  },
  GET_AUDIT_LOG_REPORT: {
    url: '/e-general/auditLog/report/excel',
    method: 'GET'
  }
}
