import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetch } from '@core/hooks'

import { Icon } from '@components/molecules'
import {
  DisplayInputWrapper,
  ErrorLabel,
  FieldWrapper,
  HiddenFileInput,
  StyledButton,
  StyledButtonTerm,
  StyledImage,
  TermWrapper,
  ButtonDisplayWrapper
} from './FileUploadField.styled'

import { omit, isEqual } from 'lodash'
import { v4 as uuid } from 'uuid'
import * as mime from 'mime-types'
import axios from 'axios'

import { APIS } from '@constants/apis'
import { UPLOAD_POLICIES } from '@constants/files'
import { callToast, getFileExtension } from '@core/utils'
import { UPLOAD_TYPES } from '@constants/component'

const FileUploadField = ({
  className,
  label,
  name,
  error,
  type,
  placeholder,
  accept = UPLOAD_POLICIES.FILE.MIME_TYPE,

  uploadType = UPLOAD_TYPES.S3,
  optionPayload,
  uploadApi = { url: '', method: '' }, // upload Api is Required when upload to Backend
  setFieldValue,
  value,
  urlImage,
  ...props
}) => {
  const [errorMsg, setErrorMsg] = React.useState()
  const [dataDisplay, setDataDisplay] = React.useState(urlImage)

  const { execute: getPresignedUploadUrl, pending: s3Pending } = useFetch(
    APIS.FILES.GET_PRESIGNED_UPLOAD_URL
  )

  const { execute: uploadToBackend, pending: backendPending } =
    useFetch(uploadApi)

  const { t } = useTranslation()

  const fileInputRef = React.useRef()

  const policiesType = React.useMemo(
    () =>
      Object.keys(UPLOAD_POLICIES).reduce((result, key) => {
        if (isEqual(UPLOAD_POLICIES[key].MIME_TYPE, accept)) {
          return key
        }
        return result
      }, ''),
    [accept]
  )

  const handleClick = React.useCallback(() => {
    fileInputRef.current.click()
  }, [])

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handleUploadToS3 = React.useCallback(
    (evt) => {
      const file = evt.target.files[0]

      if (file && file.size <= UPLOAD_POLICIES?.[policiesType]?.SIZE) {
        const uuidFileName = uuid()
        const payload = {
          ...optionPayload,
          key: uuidFileName,
          fileExtension: '.' + getFileExtension(file)
        }

        const callback = {
          onSuccess: async ({ data }) => {
            try {
              if (name && data?.key) {
                await axios.put(data.url, file, {
                  headers: {
                    'Content-Type': mime.lookup(file.name)
                  }
                })

                setFieldValue(name, data?.key)
                setDataDisplay(await getBase64(evt.target.files[0]))
                setErrorMsg()
                return
              } else {
                callToast({
                  type: 'error',
                  message: t('error.default.1001')
                })
                return
              }
            } catch (e) {
              callToast({
                type: 'error',
                message: t('error.default.file-upload')
              })
              console.log('[error] handle upload file:', e)
            }
          },
          onFailed: () => {
            callToast({
              type: 'error',
              message: t('error.default.file-upload')
            })
          }
        }

        getPresignedUploadUrl({ payload }, callback)
      } else {
        setErrorMsg(
          t('error.default.1002', {
            size: UPLOAD_POLICIES.IMAGE.SIZE / Math.pow(1024, 2)
          })
        )
      }
    },
    [getPresignedUploadUrl, name, optionPayload, policiesType, setFieldValue, t]
  )

  const handleUploadToBackend = React.useCallback(
    (evt) => {
      const file = evt.target.files[0]

      if (file && file.size <= UPLOAD_POLICIES?.[policiesType]?.SIZE) {
        const formData = new FormData()

        formData.append('file', file)

        uploadToBackend(
          {
            url: `/${optionPayload.code}/${optionPayload.companyId}`,
            payload: formData
          },
          {
            onSuccess: ({ data }) => {
              setFieldValue(name, data)
              callToast({
                type: 'success',
                message: t('common.toast.upload.success')
              })
            },
            onFailed: () => {
              callToast({
                type: 'error',
                message: t('common.toast.upload.failed')
              })
            }
          }
        )
      }
    },
    [
      t,
      name,
      optionPayload?.companyId,
      optionPayload?.code,
      policiesType,
      setFieldValue,
      uploadToBackend
    ]
  )

  const errorText = React.useMemo(() => {
    const defaultError =
      errorMsg ||
      (error && props.touched && (
        <ErrorLabel ellipsis>{errorMsg || error}</ErrorLabel>
      ))

    return defaultError
  }, [error, errorMsg, props.touched])

  const displayValue = React.useMemo(() => {
    if (uploadType === UPLOAD_TYPES.BACKEND) {
      if (placeholder === 'TERM AND CONDITION FILE') {
        return value?.termAndConditionFileName
      }
      return value?.certificateSSOName
    }

    return value
  }, [uploadType, value, placeholder])

  useEffect(() => {
    setDataDisplay(urlImage)
  }, [urlImage])

  const InputFieldStyled = () => {
    if (uploadType === UPLOAD_TYPES.S3) {
      if (dataDisplay) {
        return (
          <StyledImage
            onClick={handleClick}
            src={dataDisplay}
            alt="preview-logo"
            {...omit(props, [
              'value',
              'onChange',
              'error',
              'touched',
              'setFieldValue',
              'touched',
              'setFieldTouched'
            ])}
          />
        )
      } else {
        return (
          <StyledButton loading={s3Pending} onClick={handleClick}>
            <Icon icon="Upload" color="#fff" />
            Upload
          </StyledButton>
        )
      }
    }
    if (uploadType === UPLOAD_TYPES.BACKEND) {
      return (
        <TermWrapper>
          <ButtonDisplayWrapper>
            <StyledButtonTerm
              loading={s3Pending || backendPending}
              onClick={handleClick}
            >
              <Icon icon="Upload" color="#fff" />
              Upload
            </StyledButtonTerm>
            <div>{displayValue}</div>
          </ButtonDisplayWrapper>
          {placeholder === 'TERM AND CONDITION FILE' ? (
            <div>Format : HTML / Text</div>
          ) : (
            <div>Format : CERTIFICATE</div>
          )}
        </TermWrapper>
      )
    }
    return <></>
  }

  return (
    <FieldWrapper className={className}>
      {label && <p>{label}</p>}
      <DisplayInputWrapper>
        <InputFieldStyled />
        <HiddenFileInput
          ref={fileInputRef}
          type="file"
          aria-hidden="true"
          accept={accept}
          onChange={(evt) =>
            uploadType === UPLOAD_TYPES.S3
              ? handleUploadToS3(evt)
              : handleUploadToBackend(evt)
          }
        />
      </DisplayInputWrapper>
      {errorText}
    </FieldWrapper>
  )
}

export default FileUploadField
