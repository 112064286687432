export default {
  title: 'บริหารจัดการสายอนุมัติ',
  home: 'หน้าหลัก',
  selection: {
    'code-label': 'รหัสสายการอนุมัติ',
    'name-label': 'ชื่อสายการอนุมัติ',
    'workflowType-label': 'ประเภทสายการอนุมัติ',
    'workflowType-placeholder': 'ประเภทสายการอนุมัติ',
    'status-label': 'สถานะ',
    'code-placeholder': 'รหัสสายการอนุมัติ',
    'name-placeholder': 'ชื่อสายการอนุมัติ',
    'type-placeholder': 'ประเภทสายการอนุมัติ',
    'status-placeholder': 'กรุณาเลือก',
    'selection-type-option': {
      leave: 'การลา',
      time: 'แก้ไขเวลา',
      ot: 'โอที',
      special: 'เงินได้พิเศษ'
    },
    'selection-type-option-number': {
      1: 'การลา',
      2: 'แก้ไขเวลา',
      3: 'โอที',
      4: 'เงินได้พิเศษ'
    }
  },
  table: {
    id: 'ลำดับที่',
    code: 'รหัสสายการอนุมัติ',
    name: 'ชื่อสายการอนุมัติ',
    category: 'ประเภทสายการอนุมัติ',
    status: 'สถานะ'
  },
  form: {
    title: {
      add: 'เพิ่มสายการอนุมัติ',
      edit: 'แก้ไขสายการอนุมัติ'
    },
    'workflow-code': {
      label: 'รหัสสายการอนุมัติ',
      placeholder: 'รหัสสายการอนุมัติ'
    },
    'workflow-status': {
      label: 'สถานะ'
    },
    'workflow-name-th': {
      label: 'ชื่อสายการอนุมัติ (ภาษาไทย)',
      placeholder: 'ชื่อสายการอนุมัติ (ภาษาไทย)'
    },
    'workflow-name-en': {
      label: 'ชื่อสายการอนุมัติ (ภาษาอังกฤษ)',
      placeholder: 'ชื่อสายการอนุมัติ (ภาษาอังกฤษ)'
    },
    'workflow-category': {
      label: 'ประเภทสายการอนุมัติ',
      placeholder: 'ประเภทสายการอนุมัติ'
    },
    'workflow-description': {
      label: 'รายละเอียดเพิ่มเติม',
      placeholder: 'รายละเอียดเพิ่มเติม'
    },
    'workflow-condition': {
      label: 'เงื่อนไขของสายการอนุมัติ',
      placeholder: 'เงื่อนไขของสายการอนุมัติ'
    },
    'workflow-diagram': {
      label: 'แผนภาพสายการอนุมัติ'
    },
    'workflow-start': {
      label: 'วันที่เริ่มมีผลบังคับใช้',
      placeholder: 'กรุณาเลือกวันที่'
    },
    'workflow-end': {
      label: 'วันที่สิ้นสุดมีผลบังคับใช้',
      placeholder: 'กรุณาเลือกวันที่'
    },
    'noti-to': {
      label: 'การแจ้งผ่านเตือน Email To'
    },
    'noti-cc': {
      label: 'การแจ้งผ่านเตือน Email CC'
    },
    'employee-except': {
      label: 'พนักงานที่ยกเว้น'
    },
    'approve-line': {
      label: 'เลือกสายการอนุมัติ'
    },
    'need-approver': {
      label: 'ต้องการเพิ่มผู้อนุมัตินอกเหนือจากสายการอนุมัติที่เลือก'
    },
    'need-approver-email': {
      label: 'ผู้อนุมัติสามารถ"อนุมัติ/ไม่อนุมัติ"ผ่านอีเมล'
    },
    excludedEmployees: 'เลือกพนักงานที่ยกเว้น',
    individual: 'เลือกพนักงาน',
    oc: {
      'oc-tree': 'เลือกกลุ่มพนักงาน',
      excludedEmployees: 'เลือกพนักงานที่ยกเว้น'
    },
    approver: {
      label: 'ผู้อนุมัติลำดับที่ {{i}}',
      placeholder: 'ผู้อนุมัติลำดับที่ {{i}}'
    },
    option: {
      informed: 'แจ้ง',
      'not-informed': 'ไม่แจ้ง',
      'by-oc': 'ตาม OC',
      'by-specify': 'ระบุชื่อผู้อนุมัติ',
      'all-employee': 'พนักงานทั้งหมด',
      'specifically-employee': 'เฉพาะบางกลุ่ม',
      'individual-employee': 'รายบุคคล'
    }
  },
  'delete-row-modal': {
    title: 'คุณต้องการลบรายการที่เลือกหรือไม่',
    button: {
      accept: 'ใช่',
      cancel: 'ไม่ใช่'
    }
  }
}
