import { getCurrentMonth, getCurrentYear } from './date'

export const WEB_STATES = {
  MAIN: 'main',
  ADMIN: 'management'
}

export const ACTION_MODAL = {
  EDIT: 'EDIT',
  ADD: 'ADD'
}

export const MASTER_TITLE = {
  DIVISION: 'division',
  DEPARTMENT: 'department'
}

export const ACTION = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  DELETE: 'DELETE',
  REQUEST: 'REQUEST',
  UPDATE: 'UPDATE'
}

export const ACTIVE_STATES = {
  ACTIVE: 1,
  INACTIVE: -1
}

export const ACTIVE_STATES_V2 = {
  ACTIVE: true,
  INACTIVE: false
}

export const AUDIT_LOGS_ACTION = {
  QUERY: 'query',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete'
}

export const AUDIT_LOGS_FUNCTION = {
  AUTHENTICATION: 'auth-service',
  AUDIT_LOG: 'auditLog',
  GENERAL: 'general-service',
  E_PAYSLIP: 'payslip-service',
  E_CERTIFICATE: 'ecertificate-service',
  E_LEAVE: 'leave-service'
}

export const TRANSACTIONS_STATUS = {
  SELECT_ALL: '*',
  PENDING_APPROVAL: 'pendingApproval',
  PARTIAL_APPROVAL: 'partialApproval',
  PENDING_CANCELLATION: 'pendingCancellation',
  PARTIAL_CANCELLATION: 'partialCancellation',
  CANCEL: 'cancel',
  APPROVED: 'approved',
  REJECT: 'reject'
}

export const TRANSACTIONS_STATUS_ENUM = {
  PENDING_APPROVAL: 1,
  PARTIAL_APPROVAL: 2,
  PENDING_CANCELLATION: 3,
  PARTIAL_CANCELLATION: 4,
  CANCEL: 5,
  APPROVED: 6,
  REJECT: 7
}

export const TRANSACTIONS_STATUS_OPTION = {
  [TRANSACTIONS_STATUS_ENUM.PENDING_APPROVAL]: 'Pending Approval',
  [TRANSACTIONS_STATUS_ENUM.PARTIAL_APPROVAL]: 'Partial Approval',
  [TRANSACTIONS_STATUS_ENUM.PENDING_CANCELLATION]: 'Pending Cancellation',
  [TRANSACTIONS_STATUS_ENUM.PARTIAL_CANCELLATION]: 'Partial Cancellation',
  [TRANSACTIONS_STATUS_ENUM.CANCEL]: 'Cancel',
  [TRANSACTIONS_STATUS_ENUM.APPROVED]: 'Approved',
  [TRANSACTIONS_STATUS_ENUM.REJECT]: 'Reject'
}

export const ETxStatusText = {
  WAIT_TIME: 'Wait work time',
  PENDING_APPROVE: 'Pending approve',
  PENDING_CANCEL: 'Pending cancel',
  PARTIAL_PREVIEW: 'Partial preview',
  PARTIAL_APPROVE: 'Partial approve',
  PARTIAL_CANCEL: 'Partial cancel',
  APPROVE: 'Approve',
  CANCELED: 'Canceled',
  REJECTED: 'Reject'
}

export const ETxStatusTextOption = [
  { label: 'Wait work time', value: 'Wait work time' },
  { label: 'Pending approve', value: 'Pending approve' },
  { label: 'Pending cancel', value: 'Pending cancel' },
  { label: 'Partial approve', value: 'Partial approve' },
  { label: 'Partial preview', value: 'Partial preview' },
  { label: 'Partial cancel', value: 'Partial cancel' },
  { label: 'Approve', value: 'Approve' },
  { label: 'Canceled', value: 'Canceled' },
  { label: 'Reject', value: 'Reject' }
]

export const TRANSACTIONS_FILED = {
  STATUS: 'status'
}

export const STATUS_VALUE = {
  RESIGN: 'resign',
  ACTIVE: 'active',
  NEW_HIRE: 'new hire',
  PROBATION: 'probation'
}

export const FORMAT_DATE = {
  DEFAULT: 'DD MMMM YYYY'
}

export const TIME_UNIT_TYPE_VALUE = {
  DAY: 1,
  HOUR: 2,
  MINUTE: 3
}

export const ROUND_TYPE_VALUE = {
  NONE: 1,
  ROUND_DOWN: 2,
  ROUND_UP: 3,
  ROUND_HALF_UP: 4,
  ROUND_HALF_DOWN: 5,
  CUSTOM_01: 6
}

export const INITAIL_DATE_DATA = {
  month: getCurrentMonth,
  year: getCurrentYear
}

export const GENDER_STATE = {
  ALL_GENDER: 1,
  MALE: 2,
  FEMALE: 3
}

export const STATUS_CODE_STATE = {
  SUCCESS: 200,
  FAIL: 400
}

export const ACTION_SUCCESS_STATE = {
  APPROVE: 1,
  REJECT: -1
}
