export default {
  form: {
    'time-attached-file-label': 'อัพโหลดไฟล์(.csv , .xlsx)'
  },
  'import-table': {
    row: 'แถว',
    empCode: 'รหัสพนักงาน',
    detail: 'รายละเอียด',
    status: 'สถานะ'
  }
}
