export default {
  title: 'Workflow Setting',
  home: 'Home',
  selection: {
    'code-label': 'Workflow Code',
    'name-label': 'Workflow Name',
    'workflowType-label': 'Workflow Type',
    'workflowType-placeholder': 'Workflow Type',
    'status-label': 'Status',
    'code-placeholder': 'Workflow Code',
    'name-placeholder': 'Workflow Name',
    'type-placeholder': 'Workflow Type',
    'status-placeholder': 'Please Select',
    'selection-type-option': {
      leave: 'Leave',
      time: 'Time',
      ot: 'OT',
      special: 'Special'
    },
    'selection-type-option-number': {
      1: 'Leave',
      2: 'Time',
      3: 'OT',
      4: 'Special'
    }
  },
  table: {
    id: 'No.',
    code: 'Workflow Code',
    name: 'Workflow Name',
    category: 'Workflow Type',
    status: 'Status'
  },
  form: {
    title: {
      add: 'Add New Workflow',
      edit: 'Edit Workflow'
    },
    'workflow-code': {
      label: 'Workflow Code',
      placeholder: 'Workflow Code'
    },
    'workflow-status': {
      label: 'Status'
    },
    'workflow-name-th': {
      label: 'Workflow Name (TH)',
      placeholder: 'Workflow Name (TH)'
    },
    'workflow-name-en': {
      label: 'Workflow Name (EN)',
      placeholder: 'Workflow Name (EN)'
    },
    'workflow-category': {
      label: 'Workflow Type',
      placeholder: 'Workflow Type'
    },
    'workflow-description': {
      label: 'Workflow Description',
      placeholder: 'Workflow Description'
    },
    'workflow-condition': {
      label: 'Workflow Condition',
      placeholder: 'Workflow Condition'
    },
    'workflow-diagram': {
      label: 'Workflow Diagram'
    },
    'workflow-start': {
      label: 'Effective Start Date',
      placeholder: 'Select Date'
    },
    'workflow-end': {
      label: 'Effective End Date',
      placeholder: 'Select Date'
    },
    'noti-to': {
      label: 'Email Notification To'
    },
    'noti-cc': {
      label: 'Email Notification CC'
    },
    'employee-except': {
      label: 'Employee except'
    },
    'approve-line': {
      label: 'Select approval'
    },
    'need-approver': {
      label: 'Add approver beside the selected approver line'
    },
    'need-approver-email': {
      label: 'Approver can approve via hyperlink in the email.'
    },
    approver: {
      label: 'Approver no. {{i}}',
      placeholder: 'Approver no. {{i}}'
    },
    excludedEmployees: 'Employee except',
    individual: 'Select employee',
    oc: {
      'oc-tree': 'Select employee group',
      excludedEmployees: 'Employee except'
    },
    option: {
      informed: 'Yes',
      'not-informed': 'No',
      'by-oc': 'Organization Chart',
      'by-specify': 'Identify Approver Name',
      'all-employee': 'All',
      'specifically-employee': 'Specifically',
      'individual-employee': 'Personal'
    }
  },
  'delete-row-modal': {
    title: 'Would you like to delete this seletced item?',
    button: {
      accept: 'YES',
      cancel: 'NO'
    }
  }
}
