import axios from 'axios'
import i18n from '@core/config/i18n'
import env from '@core/config/env'
import { identity, pickBy } from 'lodash'
import { getToken, getRefreshToken, fetchTokenByRefreshToken } from './users'
import { isPublicBackendURL } from './helper'
import { WEB_STATES } from '@constants/common'
import { callToast, logout } from '.'
// import jwtDecode from 'jwt-decode'

export const getInstance = ({ isPublic, redirectWith, isBlob }) => {
  const instance = axios.create(
    isBlob === true ? { responseType: 'blob' } : null
  )
  instance.defaults.baseURL = env.API_URL

  instance.interceptors.request.use((request) => {
    const token = getToken()
    // const url = window.location.href
    // const companyGroupId = url.split('/')[3]
    request.params = {
      ...request.params
      // , companyGroupId: companyGroupId
    }

    request.headers = {
      'accept-language': i18n.language,
      'authorize-Role':
        env.WEB_STATE === WEB_STATES.MAIN ? 'employee' : 'admin',
      ...(!isPublic && { authorization: `Bearer ${token}` }),
      ...request.headers
    }

    const paramKey = ['POST', 'PUT'].includes(request.method.toUpperCase())
      ? 'data'
      : 'params'

    // If not Multipart formdata
    if (request.headers?.['Content-Type'] !== 'multipart/form-data') {
      request[paramKey] = pickBy(request[paramKey], (value) => {
        const whitelistValues = [false, 0, '']

        return identity(value) || whitelistValues.includes(value)
      })
    }

    return request
  })

  instance.interceptors.response.use(
    async (response) => {
      try {
        const blob = await response.data
        if (blob instanceof Blob) {
          return response
        }
      } catch (error) {}
      const { data, statusCode, ...rest } = response.data

      return { data, statusCode, ...rest }
    },
    async (error) => {
      const errorCode = error?.response?.status
      const requestUrl = error?.config?.url

      // If Public Endpoint ignore another response interceptor
      if (isPublicBackendURL(requestUrl)) {
        return Promise.reject(error)
      }

      // Unauthorized case
      if (errorCode === 401) {
        const refreshToken = getRefreshToken()
        const originalRequest = error.config

        // const { companyGroupCode } = jwtDecode(refreshToken)

        // Token Expired Case
        if (refreshToken && !originalRequest._retry) {
          originalRequest._retry = true

          const newToken = await fetchTokenByRefreshToken()

          if (newToken) {
            originalRequest.headers.authorization = `Bearer ${newToken}`
            let sendRequest = originalRequest

            if (['post', 'put'].includes(originalRequest.method)) {
              const newData = JSON.parse(originalRequest.data)

              const newRequest = { ...originalRequest, data: newData }
              sendRequest = newRequest
            }
            return instance(sendRequest)
          }
        }
        callToast({
          type: 'error',
          title: i18n.t(`error.default.title`),
          message: i18n.t('error.default.401')
        })

        return logout({ outSite: `/${redirectWith}` })
      }

      // TODO: Any another Error Code Logic Here

      return Promise.reject(error)
    }
  )

  return instance
}
