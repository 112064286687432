export default {
  form: {
    'import-leave-file': 'Upload File (.csv , .xlsx)',
    title: {
      'import-leave': 'Leave Day Employee Document Management'
    }
  },
  'import-table': {
    row: 'Row',
    empCode: 'Employee Code',
    detail: 'Detail',
    status: 'Status'
  }
}
