export default {
  title: 'Permission',
  home: 'Home',
  company: 'Company',
  'delete-row-modal': {
    title: 'Permission',
    content: 'Would you like to delete this seletced item?',
    button: {
      accept: 'YES',
      cancel: 'NO'
    }
  },
  form: {
    startPeriod: {
      label: 'Start date',
      placeholder: 'Start date'
    },
    endPeriod: {
      label: 'End date',
      placeholder: 'End date'
    }
  }
}
